import { useAuthenticator } from "@aws-amplify/ui-react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/vfn-logo-red.png";
import "@cloudscape-design/global-styles/dark-mode-utils.css";

const LoginPage = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from =
    (location.state?.from?.pathname || "/") +
    (location.state?.from?.search || "");

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="centerContent">
        <SpaceBetween size="xxl">
          <div
            className="awsui-util-hide-in-dark-mode"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              justifyContent: "center"
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{width: "20%"}}
            />
          </div>
          <div style={{textAlign:"center"}}> 
            <h1 style={{marginBottom: 0}}>Welcome to the </h1>
            <h1 style={{marginTop: 0, color: "#e40501"}}> Lead Management Platform</h1>
          </div>
          <Box textAlign="center" variant="h3"> Sign in using your corporate identity</Box>

          <Box textAlign="center">
            {/* to set Azure as the only provider */}
            <Button onClick={() => Auth.federatedSignIn({customProvider: 'Azure'})}>Login</Button> 

           
          </Box>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default LoginPage;
